@import url(https://fonts.googleapis.com/css?family=Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i);
// @import url(https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i);

/* Font Smoothing */
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
td,
button,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.display-1,
.display-2,
.display-3,
.display-4,
.navbar,
.brand,
.alert {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Poppins, Roboto, "Helvetica Neue", Arial, sans-serif;
  font-weight: $font-weight-normal;
}

.cr-app {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
}

.rmdp-container input{
  // display: none !important;
}

.rmdp-container .ep-arrow{
  display: none !important;
}

.rmdp-container:last-child{
  transform: none !important;
  position: static !important;
  visibility: visible !important;
}


.notification-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f5f8fd;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.notification-content {
  flex-grow: 1;
}

.notification-content h4 {
  margin-top: 0;
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: bold;
}

.notification-content p {
  margin-top: 0;
  margin-bottom: 0;
}

.close-button {
  background: none;
  border: none;
  color: #999;
  font-size: 24px;
  cursor: pointer;
}